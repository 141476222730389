import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"

const Certificates = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <>
      {selectedLanguage === "EN" ? (
        <Layout>
          <SEO title="Certifikati" />
          <div className="img-wrapper img-wrapper-about">
            <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
            <h2 className="about-header visibility-filter ">
              Integrated Management System
            </h2>
            <h2 className="about-header mobile-visible">Certificate</h2>
          </div>
          <div className="about-content mobile-visible">
            <h4 className="about-paragraph">
              Company Osijek-Koteks d.d. responsibly approaches all its business
              activities taking into account the quality of the product
              (concrete, asphalt, execution of construction), impact on the
              environment, health and safety on work. At the same time, it sets
              goals regarding construction execution, concrete and asphalt
              production, environmental protection, as well as maintenance safe
              working environment. The basic determinants of this policy are:
            </h4>
            <h4 className="about-paragraph">
              • Integrated quality management system, environmental protection,
              health and safety at work to improve the establishment of tasks,
              responsibilities and authorizations of the company's entire staff
              on all levels and maintain according to the requirements of the
              standardsIntegrated quality management system, environmental
              protection, health and safety at work to improve the establishment
              of tasks, responsibilities and authorizations of the company's
              entire staff on all levels and maintain according to the
              requirements of the standards
              <a href="https://drive.google.com/uc?export=download&id=1U5ZgUJz3eakstJgMppi6VI7rUnVSRp7b">
                ISO 9001
              </a>{" "}
              ,{" "}
              <a href="https://drive.google.com/uc?export=download&id=1d6mgJ7brdMV3Kkl9P_gCmSgOpcPT1-r1">
                ISO 14001
              </a>{" "}
              i{" "}
              <a href="https://drive.google.com/uc?export=download&id=1EMr49JEZFo4T3lBPjLL8oukCEBKh3S5l">
                ISO 45001
              </a>
              <br />• To do business in accordance with applicable laws,
              regulations and norms • Maintain cooperation with customers in
              order to meet their expectations, maintain their lasting
              satisfaction quality of our products and services and developed
              mutually confidence povjerenje
              <br /> • Develop a partnership relationship with suppliers and
              together look for solutions in the context of environmental
              protection
              <br /> • Prevent pollution, and reduce and sort all types of
              generated waste in all parts of the Organization
              <br /> • Aware of the responsibility and the need for sustainable
              development, we emphasize the prevention of environmental
              pollution, the optimal use of resources and waste management
              procedures, as well as the prevention of injuries and illnesses of
              employees and users. <br />• establish cooperation with the social
              community and all interested parties (investors, suppliers,
              subcontractors or partners, customers) through open communication{" "}
              <br />• Planned ongoing training of employees, while developing
              awareness of the importance of individual contribution to the
              quality of services, environmental protection, health and safety
              at work <br />• Recognize the sources of danger for the health and
              safety of our employees, undertake activities to eliminate them by
              introducing the necessary protective measures (training for safe
              work, protective equipment, etc.). <br />
              The company Osijek-Koteks d.d. undertakes to provide all necessary
              resources in order to implement this Policy in all business
              segments.
            </h4>
          </div>
          <div className="about-content visibility-filter">
            <h4 className="about-paragraph">
              Company Osijek-Koteks d.d. responsibly approaches all its business
              activities taking into account the quality of the product
              (concrete, asphalt, execution of construction), impact on the
              environment, health and safety on work. At the same time, it sets
              goals regarding construction execution, concrete and asphalt
              production, environmental protection, as well as maintenance safe
              working environment. The basic determinants of this policy are:
              <br />
              <br /> • Integrated quality management system, environmental
              protection, health and safety at work to improve the establishment
              of tasks, responsibilities and authorizations of the company's
              entire staff on all levels and maintain according to the
              requirements of the standardsIntegrated quality management system,
              environmental protection, health and safety at work to improve the
              establishment of tasks, responsibilities and authorizations of the
              company's entire staff on all levels and maintain according to the
              requirements of the standards{" "}
              <a href="https://drive.google.com/uc?export=download&id=1U5ZgUJz3eakstJgMppi6VI7rUnVSRp7b">
                ISO 9001
              </a>{" "}
              ,{" "}
              <a href="https://drive.google.com/uc?export=download&id=1d6mgJ7brdMV3Kkl9P_gCmSgOpcPT1-r1">
                ISO 14001
              </a>{" "}
              i{" "}
              <a href="https://drive.google.com/uc?export=download&id=1EMr49JEZFo4T3lBPjLL8oukCEBKh3S5l">
                ISO 45001
              </a>{" "}
              <br />• To do business in accordance with applicable laws,
              regulations and norms <br />• Maintain cooperation with customers
              in order to meet their expectations, maintain their lasting
              satisfaction quality of our products and services and developed
              mutually confidence povjerenje <br />
              •Develop a partnership relationship with suppliers and together
              look for solutions in the context of environmental protection{" "}
              <br />• Prevent pollution, and reduce and sort all types of
              generated waste in all parts of the Organization <br />• Aware of
              the responsibility and the need for sustainable development, we
              emphasize the prevention of environmental pollution, the optimal
              use of resources and waste management procedures, as well as the
              prevention of injuries and illnesses of employees and users.{" "}
              <br />• establish cooperation with the social community and all
              interested parties (investors, suppliers, subcontractors or
              partners, customers) through open communication <br />• Planned
              ongoing training of employees, while developing awareness of the
              importance of individual contribution to the quality of services,
              environmental protection, health and safety at work <br />•
              Recognize the sources of danger for the health and safety of our
              employees, undertake activities to eliminate them by introducing
              the necessary protective measures (training for safe work,
              protective equipment, etc.).
              <br />
              <br />
              The company Osijek-Koteks d.d. undertakes to provide all necessary
              resources in order to implement this Policy in all business
              segments.
            </h4>
          </div>
        </Layout>
      ) : (
        <Layout>
          <SEO title="Certifikati" />
          <div className="img-wrapper img-wrapper-about">
            <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
            <h2 className="about-header visibility-filter ">
              Integrirani Sustav Upravljanja
            </h2>
            <h2 className="about-header mobile-visible">Certifikati</h2>
          </div>
          <div className="about-content mobile-visible">
            <h4 className="about-paragraph">
              Tvrtka Osijek-Koteks d.d. odgovorno pristupa svim svojim poslovnim
              aktivnostima vodeći računa o kvaliteti proizvoda (beton, asfalt,
              izvođenje građenja), utjecaju na okoliš, zdravlje i sigurnost na
              radu. Pri tome postavlja ciljeve koji se tiču izvođenja građenja,
              proizvodnje betona i asfalta, očuvanja okoliša, kao i održavanja
              sigurnog radnog okruženja. Temeljne odrednice ove politike su:
            </h4>
            <h4 className="about-paragraph">
              • Integrirani sustav upravljanja kvalitetom, zaštitom okoliša,
              zdravlja i sigurnosti na radu poboljšavati uspostavom zadaća,
              odgovornosti i ovlaštenja cjelokupnog osoblja tvrtke na svim
              razinama i održavati prema zahtjevima normi{" "}
              <a href="https://drive.google.com/uc?export=download&id=1U5ZgUJz3eakstJgMppi6VI7rUnVSRp7b">
                ISO 9001
              </a>{" "}
              ,{" "}
              <a href="https://drive.google.com/uc?export=download&id=1d6mgJ7brdMV3Kkl9P_gCmSgOpcPT1-r1">
                ISO 14001
              </a>{" "}
              i{" "}
              <a href="https://drive.google.com/uc?export=download&id=1EMr49JEZFo4T3lBPjLL8oukCEBKh3S5l">
                ISO 45001
              </a>{" "}
              <br />• Poslovati u skladu s važećim zakonima, propisima i normama
              • Održavati suradnju s kupcima kako bi ispunili njihova
              očekivanja, održali njihovo trajno zadovoljstvo kvalitetom naših
              proizvoda i usluga i razvijali međusobno povjerenje
              <br /> • Razvijati partnerski odnos sa dobavljačima i zajedno
              tražiti rješenja u kontekstu zaštite okoliša
              <br /> • Sprječavati onečišćenja, te smanjivati i razvrstavati sve
              vrste nastalog otpada u svim dijelovima Organizacije
              <br /> • Svjesni odgovornosti i potrebe održivog razvoja, naglasak
              stavljamo na prevenciju onečišćenja okoliša, optimalno korištenje
              resursa i postupke gospodarenja otpadom, kao i na prevenciju
              ozljeda i bolesti zaposlenika i korisnika <br />• Sa društvenom
              zajednicom i svim zainteresiranim stranama (investitori,
              dobavljači, podizvođači ili partneri, kupci) uspostaviti suradnju
              kroz otvorenu komunikaciju <br />• Planski provoditi stalnu
              izobrazbu zaposlenika, pri tome razvijati svijest o važnosti
              pojedinačnog doprinosa kvaliteti usluga, zaštiti okoliša, zdravlja
              i sigurnosti na radu <br />• Prepoznavati izvore opasnosti za
              zdravlje i sigurnost naših zaposlenika, poduzimati aktivnosti za
              njihovu eliminaciju uvođenjem potrebnih zaštitnih mjera
              (osposobljavanje za siguran rad, zaštitna oprema i dr.). <br />
              Tvrtka Osijek-Koteks d.d obvezuje se osigurati sve potrebne
              resurse kako bi se ova Politika provodila u svim segmentima
              poslovanja.
            </h4>
          </div>
          <div className="about-content visibility-filter">
            <h4 className="about-paragraph">
              Tvrtka Osijek-Koteks d.d. odgovorno pristupa svim svojim poslovnim
              aktivnostima vodeći računa o kvaliteti proizvoda (beton, asfalt,
              izvođenje građenja), utjecaju na okoliš, zdravlje i sigurnost na
              radu. Pri tome postavlja ciljeve koji se tiču izvođenja građenja,
              proizvodnje betona i asfalta, očuvanja okoliša, kao i održavanja
              sigurnog radnog okruženja. Temeljne odrednice ove politike su:
              <br />
              <br /> • Integrirani sustav upravljanja kvalitetom, zaštitom
              okoliša, zdravlja i sigurnosti na radu poboljšavati uspostavom
              zadaća, odgovornosti i ovlaštenja cjelokupnog osoblja tvrtke na
              svim razinama i održavati prema zahtjevima normi{" "}
              <a href="https://drive.google.com/uc?export=download&id=1U5ZgUJz3eakstJgMppi6VI7rUnVSRp7b">
                ISO 9001
              </a>{" "}
              ,{" "}
              <a href="https://drive.google.com/uc?export=download&id=1d6mgJ7brdMV3Kkl9P_gCmSgOpcPT1-r1">
                ISO 14001
              </a>{" "}
              i{" "}
              <a href="https://drive.google.com/uc?export=download&id=1EMr49JEZFo4T3lBPjLL8oukCEBKh3S5l">
                ISO 45001
              </a>{" "}
              <br />• Poslovati u skladu s važećim zakonima, propisima i normama{" "}
              <br />• Održavati suradnju s kupcima kako bi ispunili njihova
              očekivanja, održali njihovo trajno zadovoljstvo kvalitetom naših
              proizvoda i usluga i razvijali međusobno povjerenje <br />•
              Razvijati partnerski odnos sa dobavljačima i zajedno tražiti
              rješenja u kontekstu zaštite okoliša <br />• Sprječavati
              onečišćenja, te smanjivati i razvrstavati sve vrste nastalog
              otpada u svim dijelovima Organizacije <br />• Svjesni odgovornosti
              i potrebe održivog razvoja, naglasak stavljamo na prevenciju
              onečišćenja okoliša, optimalno korištenje resursa i postupke
              gospodarenja otpadom, kao i na prevenciju ozljeda i bolesti
              zaposlenika i korisnika <br />• Sa društvenom zajednicom i svim
              zainteresiranim stranama (investitori, dobavljači, podizvođači ili
              partneri, kupci) uspostaviti suradnju kroz otvorenu komunikaciju{" "}
              <br />• Planski provoditi stalnu izobrazbu zaposlenika, pri tome
              razvijati svijest o važnosti pojedinačnog doprinosa kvaliteti
              usluga, zaštiti okoliša, zdravlja i sigurnosti na radu <br />•
              Prepoznavati izvore opasnosti za zdravlje i sigurnost naših
              zaposlenika, poduzimati aktivnosti za njihovu eliminaciju
              uvođenjem potrebnih zaštitnih mjera (osposobljavanje za siguran
              rad, zaštitna oprema i dr.) .<br />
              <br />
              Tvrtka Osijek-Koteks d.d obvezuje se osigurati sve potrebne
              resurse kako bi se ova Politika provodila u svim segmentima
              poslovanja.
            </h4>
          </div>
        </Layout>
      )}
    </>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "nasip_sava.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Certificates
